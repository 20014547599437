@font-face {
  font-family: "Lato-Regular";
  src: url("../public/fonts/Lato-Regular.ttf")
}

@font-face {
  font-family: "MedulaOne-Regular";
  src: url("../public/fonts/MedulaOne-Regular.ttf")
}

/* https://mycolor.space/?hex=%23F7E7CE&sub=1 */
:root {
  --champagne: #fdf9f0;
  --blue: #4e3ccc;
  --darkblue: #231871;
  --clay: #b5aa99;
  --seagreen: #b0d5ca;
  --darkgreen: #7b9e94;
}

body {
  text-align: center;
  /*background: linear-gradient(#f7e7ce, #ffffff);*/
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Lato-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--champagne);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-clay {
  background-color: var(--clay) !important;
}

.mobile-header {
  height: 60px;
  position: sticky;
  top: 0;
}

.header {
  font-family: "MedulaOne-Regular"
}

.copy {
  font-family: "Lato-Regular"
}

.nav-pills {
  --bs-nav-pills-link-active-bg: var(--blue)
}

.nav-pills:hover {
  --bs-nav-pills-link-active-bg: var(--darkblue);
}

.btn-primary {
  background-color: var(--blue) !important;
}

.btn-primary:hover {
  background-color: var(--darkblue) !important;
}

.form-check-input {
  border: 2px solid var(--darkblue) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  height: 100%;
}

#js-canvas {
  background: repeating-linear-gradient(45deg, #171414, #ebf8e1 20%, #a6641c 5%);
}