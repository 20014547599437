.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.map-container {
       
    
    width: 100%;
    height: calc(100% - 56px);
}


.mapboxgl-popup {
    color: black;
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.map-overlay {
    /* font:
      12px/20px 'Helvetica Neue',
      Arial,
      Helvetica,
      sans-serif; */
    position: absolute;
    width: fit-content;
    left: 0;
    right: 0;
    bottom: 40px;
    margin: 0 auto;
    padding: 10px;
}

.map-overlay .map-overlay-inner {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
}

.map-overlay-inner fieldset {
    display: flex;
    justify-content: space-between;
    border: none;
}

.map-overlay-inner label {
    font-weight: bold;
    margin-right: 10px;
}

.map-overlay-inner .select-fieldset {
    display: block;
}

.map-overlay-inner .select-fieldset label {
    display: block;
    margin-bottom: 5px;
}

.map-overlay-inner .select-fieldset select {
    width: 100%;
}